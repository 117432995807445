import 'browser-v1.0.0';
import 'phone-mask-v2.0.0';

$(() => {

    // Phone Form
    const telephone = $('form[name="telephone"]');
    const phones = $('input[name="phone"]', telephone);

    if (telephone[0]) {
        // Phone Number Masking
        $(phones).mask("(999) 999-9999");

        $(telephone).submit(e => {
            e.preventDefault();
            const phone = $('input[name="phone"]', e.target);
            $(telephone).removeClass('error');
            let phoneNumber = $(phone).val();
            if (/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/g.test(phoneNumber)) {
                phoneNumber = phoneNumber.replace(/\(|\)| |-/g, '');

                //result page
                let resultpage = '/number/' + phoneNumber + '/';
                window.location = resultpage;

                //update 'click here' url in #loading
                $('#loading-clickhere').attr("href", resultpage);

                //add class to body for no-scroll CSS
                $("body").addClass("loading-screen");

                //show loading screen when user submits form
                $('#loading').css("display", "block");

                //hide loading screen if user presses ESC key
                $(document).on('keydown', function (event) {
                    if (event.key == "Escape") {
                        $('#loading').css("display", "none");

                        //remove class in body
                        $("body").removeClass("loading-screen");
                    }
                });

            } else {
                $(telephone).addClass('error');
            }
        });
    }

    //collapsible content
    let collapsibleBtn = document.getElementsByClassName("collapsible-button");
    let i;

    for (i = 0; i < collapsibleBtn.length; i++) {
        collapsibleBtn[i].addEventListener("click", function () {
            this.classList.toggle("collapsible-button-active");
            this.parentNode.classList.toggle("collapsible-container-active");

            //update button when clicked
            this.innerHTML === "+" ? this.innerHTML = "-" : this.innerHTML = "+";
        });
    }    

	$('.area-code-nav li:first, .area-list:first').addClass('active');

	$('.area-code-nav li').click(function () {
		$('.area-code-nav li, .area-list').removeClass('active');
		
		const dataBlock = $(this).data('id');

		$(`.area-code-nav li[data-id="${dataBlock}"], .area-list#${dataBlock}`).addClass('active');
	});

    // state search
    document.getElementById('submitState').disabled = true; //disable submit button by default

    if (document.querySelector('input[name="statelistitem"]')) {
        document.querySelectorAll('input[name="statelistitem"]').forEach((elem) => {
            elem.addEventListener("change", function (event) {
                let item = event.target.value.toLowerCase().replaceAll(' ', '-');
                if (item && item !== 'all-states') {
                    document.getElementById('stateImage').src = '/_/images/states/' + item + '.svg';
                    document.getElementById('selectState').action = '/' + item + '/';
                    document.getElementById('submitState').disabled = false; //enable submit button
                } else {
                    document.getElementById('stateImage').src = '/_/images/states/all-states.svg';
                    document.getElementById('selectState').removeAttribute('action');
                    document.getElementById('submitState').disabled = true; //disable submit button
                }
            });
        });
    }

});
